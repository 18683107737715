.assign_passes__form {
  button[name="reassign"] {
    background: var(--c-yellow-300);
  }

  .status {
    margin-top: 1rem;
  }

  input[type="email"],
  input[type="text"] {
    width: 35%;
  }

  input[disabled] {
    color: var(--c-yellow-300);
    background: var(--c-gray-900);
    border-color: var(--c-teal-800);
    cursor: not-allowed;

    &::placeholder {
      opacity: 0.25;
      color: var(--c-teal-600);
    }
  }

  button[disabled] {
    background: var(--c-gray-300);
    color: var(--c-gray-600);
    cursor: default;
  }

  @media (max-width: 40rem) {
    input[type="email"],
    input[type="text"] {
      display: inline-block;
      width: 80vw;
      margin: .5em auto;
    }
    .registration__form .status {
      text-align: center;
    }
  }
}