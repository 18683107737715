.shoutout {
  text-align: center;
  line-height: 1.5;
}

.supporters {
  display: grid;

  width: 100%;
  max-width: none;

  grid-template-columns: repeat(auto-fit, 8rem);
  grid-auto-rows: 8rem;
  gap: 1rem;
  justify-content: center;

  list-style: none;
  padding: 0;

  @media (max-width: 40rem) {
    grid-template-columns: 16rem;
    grid-auto-rows: 12rem;
  }

  &__logo {
    display: flex;
    flex-direction: column;
  
    border-radius: .75rem;

    font-size: .85rem;
    padding: .5rem;
    text-align: center;    align-items: center;

    a {
      display: contents;
    }

    img {
      width: 4rem;
      max-height: 4rem;
      margin: .5rem;
      object-fit: contain;
      border-radius: 1rem;
    }
  }
}
