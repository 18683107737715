.hero {
  display: grid;
  grid-template-areas: "content logo";
  grid-template-columns: minmax(auto, 30rem) auto;
  align-items: center;
  justify-content: space-between;
  gap: 0 2rem;

  margin: 3rem 0;

  @media (max-width: 40rem) {
    grid-template-areas:
      "content"
      "logo";
    justify-content: center;
    text-align: center;
    gap: 3rem 0;
  }

  .logo {
    grid-area: logo;
  }
}

.hero .content {
  grid-area: content;
}


.hero .title,
.hero .title--small,
.hero .title--big {
  display: block;
  font-family: var(--title-font);
}

.hero .title--small {
  font-size: 1.25rem;
  color: white;
}

.hero .title--big {
  color: var(--c-magenta-400);
  font-size: 2.25rem;

  margin: .5rem 0 1rem;
}

.hero .text {
  font-size: 1.25rem;
  line-height: 1.6rem;

  margin: 1rem 0 2rem;
}


/* "Rotating Globe" SVG logo animation */
#clipGlobe {
  clip-path: url(#globeClipPath);
}
#mapGlobe {
  transform: translateX(-100px);
  animation: linear 30s mapmove infinite;
}

@keyframes mapmove {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(450px);
  }
}
