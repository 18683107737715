.timezone__box {
  color: var(--c-gray-900);
  background: var(--c-magenta-400);
  border-radius: .25rem;

  padding: 1.5rem;
  margin: 1rem 0 2rem;

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;


  /* Allow wrapping on mobile sizes */
  @media (max-width: 40rem) {
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    padding: 1rem;
  }  


  &__label {
    flex: 16ch 0;
    @media (max-width: 40rem) {
      flex: 0;
    }
  }

  &__current {
    flex: 1;
  }

  &__change {
    background-color: transparent;
    color: white;
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active
    {
      background: transparent;

      filter: invert();
    }

    /* Clock icon */
    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: .625em;
      vertical-align: -1px;
      /* SVG-s attached as background image urls don't respond to currentColor
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .25a7.75 7.75 0 100 15.5A7.75 7.75 0 008 .25zm0 14a6.25 6.25 0 110-12.5 6.25 6.25 0 010 12.5zM9.9 11c.2.13.41.1.54-.1l.6-.78c.12-.18.09-.4-.1-.53L8.88 8.06V3.62a.4.4 0 00-.38-.37h-1a.38.38 0 00-.38.38v5.15c0 .1.04.22.13.28L9.91 11z' fill='currentcolor'/%3E%3C/svg%3E");

        OTOH WebKit/Blink doesn't support clip-path: path()
        */
      background: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .25a7.75 7.75 0 100 15.5A7.75 7.75 0 008 .25zm0 14a6.25 6.25 0 110-12.5 6.25 6.25 0 010 12.5zM9.9 11c.2.13.41.1.54-.1l.6-.78c.12-.18.09-.4-.1-.53L8.88 8.06V3.62a.4.4 0 00-.38-.37h-1a.38.38 0 00-.38.38v5.15c0 .1.04.22.13.28L9.91 11z' fill='#5bd2ab'/%3E%3C/svg%3E");
      filter: var(--to-white);
      transform: scale(1.25);
    }

  }
}

.tzdialog {
  border: none;
  background: var(--c-gray-800);
  color: var(--c-gray-100);
  padding: 2rem;
  margin: 0 auto;

  width: 80vw;
  height: 24rem;
  max-width: 32rem;
  top: calc( 50vh - 24rem / 2);
  left: calc(50vw - 16rem);
  right: calc(50vw + 16rem);

  @media (max-width: 36rem) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: unset;
    max-height: unset;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background: linear-gradient(to top, var(--c-teal-800) 50%, rgba(0,0,0,.5));
    text-align: center;
  }

  select {
    max-width: 90%;
    margin: 1em;
  }
}

dialog {
  &:not([open]) {
    display: none;
  }

  &[open] {
    position: fixed;

    &::backdrop {
      background: rgba(0,0,0,.6);
    }

    &+.backdrop {
      position: fixed;
      background: rgba(0,0,0,.6);
      top: 0; left: 0; right: 0; bottom: 0;
      width: 100vw;
    }
  }
}
dialog:not([open]) {
  display: none;
}