* {
  box-sizing: border-box;
}

html, body {
  background-color: var(--c-gray-900);

  font-family: var(--flowtext-font);
  color: white;

  margin: 0; padding: 0;

  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  display: grid;

  /*960px + 2x32px*/
  grid-template-columns: 1fr min(64rem, calc(100vw - 4rem)) 1fr;
  gap: 0 2rem;

  scrollbar-color: #495460 rgba(255, 255, 255, 0);
  scrollbar-width: thin;

  /* stretch to viewport height */
  min-height: 100vh;

  > header,
  > main, 
  > footer {
    grid-column: 2/3;
  }
}

a {
  color: var(--c-teal-400);
}
:visited {
  color: none; /* this is invalid but works! */
}

a:hover,
a:active {
  color: var(--c-teal-300);
}
a:focus {
  outline-style: solid;
  outline-color: var(--c-magenta-400);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-font);
  color: var(--c-magenta-400);
  margin: 1em 0 .5em;
}

h1 {
  font-size: 2.5rem;
  margin: 1em 0;
}
h2 { font-size: 2.25rem; }
h3 { font-size: 2rem; }
h4 { font-size: 1.675rem; }
h5 { font-size: 1.5rem; }

p,
ul, ol,
blockquote {
  max-width: 48rem;

  font-family: var(--flowtext-font);
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.3;

  margin: 0 0 1rem;
}

blockquote {
  border-left: .5rem solid var(--c-gray-700);
  padding:  0 0 0 .875rem;
}

hr {
  margin: 4.5rem 0;
  border: none;
  border-bottom: 1px solid var(--c-gray-500);
}

ul, ol {
  list-style-position: outside;
  padding: 0 0 0 1em;
}

li {
  margin: .3em 0;
}


input[type="submit"],
button,
.as-button {
  background-color: var(--c-teal-400);
  border-style: none;
  /*chonky*/border-radius: .25rem .25rem .25rem .75rem;

  padding: .8rem 2rem;

  font-family: var(--title-font);
  font-size: 1.15rem;
  line-height: 1.4rem;
  color: var(--c-gray-900);
  text-transform: uppercase;

  cursor: pointer;

  &:active,
  &:hover {
    background-color: var(--c-teal-300);
  }
  &:focus {
    background-color: var(--c-magenta-300);
    outline: none;
  }

  &.alt {
    background-color: var(--c-magenta-400);

    &:active,
    &:focus,
    &:hover {
      background-color: var(--c-magenta-300);
    }
  }

  &[disabled] {
    background: var(--c-teal-900);
    color: var(--c-gray-600);
    cursor: default;
    /* border-color: transparent; */
  } 
}
a.as-button {
  text-decoration: none;
  &:active, &:hover, &:focus {
    color: white;
  }
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  border: 1px solid var(--c-teal-400);
  border-radius: .25rem;
  background: var(--c-gray-900);

  color: white;
  padding: .75rem 1.25rem;

  &::placeholder {
    color: white;
    opacity: .5;
  }
  &:focus::placeholder {
    opacity: .25;
  }

  &[disabled] {
    opacity: .5;
  }
}
input[type="number"] {
	padding-right: .1rem;
	padding-left: .1rem;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	text-align: center;
}

/* Small numeric input field (e.g. for PIN codes */
input[type="tel"] {
  font-size: 1.6rem;
  letter-spacing: .2rem;
  width: 10ch;
  color: var(--c-magenta-300);
  text-align: center;
}


textarea {
  width: 80%;
  max-width: 40em;
  height: 8em;
  max-height: 20em;

  font-family: var(--flowtext-font);
}

/* Button with attached text input */
.text-and-button {
  display: inline-flex;
  padding: 0;
  border: 1px solid var(--c-teal-400);
  border-radius: .25rem;
  background: var(--c-gray-900);
  white-space: nowrap;

  &:focus-within {
    filter: drop-shadow(0px 0px 10px #00CFAB);
  }

  input {
    background: transparent;
    border: none;
    margin: 0;
    outline: none;
    flex: 1;
  }

  button {
    margin: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid transparent;

    &[disabled] {
      border-top-color: var(--c-teal-400);
      border-bottom-color: var(--c-teal-400);
      border-right-color: var(--c-teal-400);
      border-left-color: transparent;
    }
  }

  @media (max-width: 40rem) {
    flex-direction: column;

    input {
      width: 100%;
    }
    button {
      border-bottom-left-radius: .25rem;
      border-top-right-radius: 0;

      &[disabled] {
        border-left-color: var(--c-teal-400);
        border-top-color: transparent;
      }
    }
  }
}


/* Center align block contents */
.align-center {
  text-align: center;
}

/* Big top- & short bottom margins
  (for use with big page titles, like that of the start page) */
.margin-title-big {
  margin-top: 6rem;
  margin-bottom: 1.5rem;
}

/* Styling of direct links (generated by the Markdown parser) */
.direct-link {
  font-weight: bold;
  text-decoration: none;

  display: inline-block;
  position: absolute;
  left: -.9em;
  margin: -.2em;
  padding: .2em;

  opacity: 0;

  :focus > &,
  :hover > &,
  &:hover,
  &:focus {
    opacity: .4;
  }
}



/* Prepend an email icon to cemail contact links */
a[href^="mailto:"] {
  /* prevent text wrapping */
  white-space: nowrap;

  /* icon */
  &::before {
    content: "";
    display: inline-block;

    /* TODO: import from source/figma? SVGO+https://yoksel.github.io/url-encoder/ */
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.6 5l-5.4 4c-.7.5-2 1.7-3.2 1.7-1.3 0-2.5-1.2-3.3-1.7L.3 5C.2 4.9 0 5 0 5v7.2c0 1 .7 1.7 1.7 1.7h14.6c1 0 1.7-.7 1.7-1.7V5.1c0-.1-.2-.2-.4-.1zM9 9.5c.8 0 2-1 2.6-1.4 4.6-3.4 5-3.7 6-4.6.3-.1.4-.4.4-.6v-.7c0-1-.8-1.7-1.7-1.7H1.7C.7.5 0 1.3 0 2.2v.7c0 .2.1.5.3.6 1 .9 1.4 1.2 6.1 4.6.6.4 1.8 1.4 2.6 1.4z' fill='%235BD2AB'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    position: relative;
    bottom: -2px;
    width: 18px;
    height: 14px;

    margin-right: .375em;
  }
}
