header {
  display: grid;
  grid-template-areas: "logo navigation";
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 8rem;
  align-items: center;
  gap: 2rem;
}

header .logotype {
  grid-area: logo;
  max-width: 100%;

  &:focus {
    outline: .2rem solid var(--c-magenta-400);
    outline-offset: .2rem;
  }
  svg {
    max-width: 100%;
  }
}


header nav {
  grid-area: navigation;
  justify-self: end;
  align-self: center;

  display: flex;
  gap: 2rem;
}

header nav a {
  &,
  &:visited {
    font-family: var(--title-font);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.25rem;
  
    padding: .4rem .9rem;
  
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-decoration: none;
  }

  &:hover,
  &:active {
    filter: brightness(125%) saturate(90%);
  }
  &:focus {
    color: var(--c-magenta-300);
    outline: none;
  }
}

@media (min-width: 40rem) {

  header nav a:last-of-type {
    background-color: var(--c-teal-400);
    color: var(--c-gray-900);

    /*chonky*/border-radius: .25rem .25rem .25rem 1rem;
  
    &:focus {
      outline: none;
      background-color: var(--c-magenta-300);
      color: var(--c-gray-900);
    }
  }
}


@media (max-width: 40rem) {
  header {
    grid-template-areas: "logo" "navigation";
    grid-template-columns: auto;
    grid-template-rows: auto;
    padding: 1rem;     
    place-items: center;
    gap: .5rem;
  }
  header nav {
    flex-direction: column;
    justify-self: unset;
    justify-content: space-evenly;
    gap: .25rem;
  }
  header nav a {
    text-align: center;
  }
}