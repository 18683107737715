.session__tags {
  list-style: none;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: .625rem;
}

.session__tag {
  font-weight: bold;
  font-size: .675rem;
  padding: .375em 1em;
  border-radius: 1em;

  background: var(--c-yellow-300);
  color: var(--c-yellow-900);

  /* don't wrap contents */
  white-space: nowrap;

  /* Talk language */
  &.t-lang,
  &.t-cc,
  &.t-vo
  {
    background: var(--c-teal-400);
    color: var(--c-teal-900);

    &::before {
      content: '';
      background-repeat: no-repeat;
      background-position: center;

      /* don't display text content */
      font-size: 0;

      /* move closer to the noodle edge */
      padding: .5rem 0.5rem;
      margin: 0 .2rem 0 0;
      vertical-align: 0.25rem;

    }
  }

  &.t-lang::before {
    content: 'Original language:';
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 6.7h-.02s-.1.52-.18.83l-.27.94h.92l-.25-.95c-.1-.3-.2-.82-.2-.82zM15.4 4H.6a.6.6 0 00-.6.6v6.8c0 .35.25.6.6.6h14.8a.6.6 0 00.6-.6V4.6a.6.6 0 00-.6-.6zm-9.57 6.4h-.58c-.13 0-.25-.08-.28-.2l-.25-.8h-1.5l-.22.8c-.02.13-.15.2-.27.2h-.58c-.2 0-.35-.2-.27-.38l1.42-4.2c.05-.12.15-.22.28-.22h.82c.13 0 .22.1.27.23l1.43 4.2c.08.17-.07.37-.27.37zM15 11H8V5h7v6zM9.3 7.3h2.75c-.15.33-.38.65-.65.98l-.45-.6c-.07-.13-.25-.18-.4-.1l-.35.22c-.15.08-.17.27-.07.42.14.23.34.5.57.75-.22.2-.47.38-.72.53-.13.1-.18.28-.1.4l.2.35c.1.15.27.2.42.1.32-.17.63-.42.9-.65.28.23.55.45.88.65.15.1.32.05.42-.1l.2-.35c.07-.13.03-.3-.1-.4a6.18 6.18 0 01-.68-.53 4.8 4.8 0 001.03-1.67h.35a.3.3 0 00.3-.3v-.4c0-.15-.15-.3-.3-.3h-1.6v-.4c0-.15-.15-.3-.3-.3h-.4a.3.3 0 00-.3.3v.4H9.3a.3.3 0 00-.3.3V7c0 .17.13.3.3.3z' fill='%23225147'/%3E%3C/svg%3E");
    cursor: help;
  }
  &.t-cc::before {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.97 4H4.03C3.45 4 3 4.46 3 5v6a1 1 0 001.03 1h8.94c.56 0 1.03-.44 1.03-1V5c0-.54-.47-1-1.03-1zm-.13 7H4.16c-.09 0-.13-.04-.13-.13V5.13c0-.07.04-.13.13-.13h8.68c.06 0 .13.06.13.13v5.75c0 .08-.07.12-.13.12zM8.29 9.23l-.41-.58c-.05-.07-.15-.07-.2-.03-.9.8-2 .34-2-.66 0-.98 1.17-1.33 2.05-.67.06.06.15.04.19-.02l.39-.64c.02-.05.02-.09-.02-.13-1.1-1.02-3.72-.56-3.72 1.48 0 2.08 2.55 2.58 3.72 1.4a.1.1 0 000-.15zm4.1 0l-.43-.58c-.04-.07-.13-.07-.2-.03-.88.8-1.97.34-1.97-.66 0-.98 1.16-1.33 2.02-.67.06.06.17.04.21-.02l.37-.64c.04-.05.02-.09-.02-.13-1.1-1.02-3.7-.56-3.7 1.48 0 2.08 2.56 2.58 3.7 1.4.04-.05.06-.1.02-.15z' fill='%23225147'/%3E%3C/svg%3E");
  }
  &.t-vo::before {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 561 561' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M561 266.26V332.643C561 371.518 529.41 403.248 490.262 404.073C457.188 487.534 377.072 551.453 271.062 542.793C264.246 553.379 252.374 560.785 239.406 559.941C224.047 558.933 198.804 548.871 198.804 548.871C179.109 542.566 168.257 521.617 174.613 502.148C179.636 486.777 193.988 476.46 210.336 476.449C219.855 476.449 250.957 487.531 250.957 487.531C260.641 490.621 268.582 497.472 273.055 506.371C290.117 511.684 399.425 510.996 454.015 395.351C430.898 382.925 416.37 359.136 416.37 332.652L416.374 266.261C416.374 238.3 432.94 213.062 458.249 201.456C445.851 115.851 371.847 52.066 283.559 52.066C194.11 52.066 119.859 116.925 108.519 203.866C131.246 216.405 145.621 240.261 145.621 266.268V332.651C145.621 372.042 113.187 404.096 73.3089 404.096C33.4299 404.092 0.996887 372.045 0.996887 332.647V266.264C0.996887 233.244 24.3449 204.487 56.2979 196.834C63.3409 143.865 89.2159 95.064 129.595 58.824C171.876 20.89 226.548 0.0039978 283.555 0.0039978C340.735 0.0039978 395.535 21.016 437.865 59.156C478.435 95.715 504.26 144.937 510.99 198.306C540.72 207.747 560.994 234.876 560.994 266.267L561 266.26Z' fill='%23225147'/%3E%3C/svg%3E");
    background-size: 14px;
    margin-right: 4px;
  }
}
