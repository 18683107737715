main.information {
  h2 {
    margin: 4rem 0 1rem;
  }

  h3 {
    font-family: var(--flowtext-font);
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5rem;

    color: var(--c-teal-400);
  }
}
