.registration__form {
  margin: 1rem auto 0;
  max-width: 36rem;
  padding: 0;
  line-height: 1.4;

  i,
  > * {
    display: block;
    margin: .375rem 0;
  }

  fieldset {
    margin: 1.5rem 0;
    padding: 0;
    width: 100%;
    border: none;
    border-top: 1px solid var(--c-magenta-400);
    padding-top: .625rem;

    legend {
      font-family: var(--title-font);
      color: var(--c-magenta-400);
      font-size: 1.125rem;
    }
  }

  select,
  input[type="email"],
  input[type="text"] {
    margin: .375rem 0;
    width: 16em;
  }

  input[type="submit"] {
    display: block;
    margin: 2rem auto;
  }
}
