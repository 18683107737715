.translations:empty {
  display: none;
}

.translations {
  /* Auto-sized flex container */
  display: inline-flex;
  list-style: none;

  border: .125rem solid var(--c-teal-400);
  border-radius: .5rem;

  margin: 0 0 2rem;
  padding: 0;

  /* Active/hover state */
  &:focus-within,
  &:hover {
    border-color: var(--c-teal-300);
  }

  &__lang {
    text-align: center;
    white-space: nowrap;
    color: var(--c-teal-400);

    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;

    margin: 0;
    padding: 0;
    border: none;

    /* Border between items */
    &:not(:last-of-type) {
      border-right: .125rem solid var(--c-teal-400);
    }

    /* Active/hover state */
    &:focus-within,
    &:hover {
      background-color: var(--c-teal-300);
      border-color: var(--c-teal-300);
      color: var(--c-gray-900);
    }

    & > a {
      text-decoration: none;
      color: currentColor;
      display: block;
      border: 1em solid transparent;

      /* Better keyboard navigation outline */
      &:focus {
        outline: .2rem solid var(--c-magenta-600);
      }
    }

  }
}

@media (max-width: 40rem) {
  .translations {
    flex-direction: column;
    margin: 0 0 1rem;
  }
  .translations>li {
    flex: 0;
  }
  .translations>li:not(:last-of-type) {
    border-right: none;
    border-bottom: .125rem solid var(--c-teal-400);
  }
  .translations>li>a {
     border-width: .75em 2em;
  }
}
