header, main {
  position: relative;
}

.rustfest_backdrop {
  position: fixed;
  z-index: 0;
  pointer-events: none;
  top: 6rem;
  left: calc(50vw - ( 1374px / 2 ));

  @media (max-width: 40rem) {
    display: none;
  }
}
