.event {
  display: grid;
  gap: .25rem;
  margin: 1rem 0 6rem;

  & + .event {
    margin-top: -2rem;
  }

  /* Collapsing */
  &[data-is="collapsed"] {
    /* reduced margin */
    margin-bottom: 3rem;

    /* hide collapsed sessions */
    > .session {
      display: none;
    }
  }

  /* Event header */
  &__hdr {
    padding: 1rem;
    border-radius: .25rem;

    background: var(--c-teal-400);
    color: var(--c-teal-900);

    display: flex;
    align-items: center;

    /* Wrap to two rows in mobile views */
    @media (max-width: 40rem) {
      flex-wrap: wrap;
      gap: .75rem;
      justify-content: center;
      text-align: center;
    }

    &__title {
      color: inherit;
      font-size: 1.25rem;
      margin: 0;
      flex: 1;

      /* Full-width on mobile sizes */
      @media (max-width: 40rem) {
        flex: 100%;
      }  
    }

    &__start {
      background:  var(--c-teal-300);
      font-size: .875rem;
      padding: .75em 1.25em;
      border-radius: calc(1em + 2 * .75em);
    }

    &__btn {
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0.4rem 1rem;
      border-radius: .25rem;
      margin: 0 0 0 1rem;

      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.06 3.43a1 1 0 011.65 0l5.88 8.5a1 1 0 01-.82 1.56H2a1 1 0 01-.82-1.57l5.88-8.49z' fill='%23225147'/%3E%3C/svg%3E");

      /* no margin while centered */
      @media (max-width: 40rem) {
        margin: 0;
      }


      &[data-action="toggle-collapse"] {
        transform: rotate(0deg);

        @at-root .event[data-is="collapsed"] .event__hdr__btn[data-action="toggle-collapse"] {
          transform: rotate(180deg);
        }
      }  
    }
  }
}