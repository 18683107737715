footer {
  position: relative;
  color: var(--c-gray-900);

  grid-column: 1/4;
  margin-top: 3rem;

  font-size: .875rem;

  /*display: grid;
  grid-template-columns: 1fr min(64rem, calc(100vw - 4rem)) 1fr;
  gap: 0 2rem;*/

  --letterbox-size: calc( ( 100vw - min(64rem, calc(100vw - 4rem)) ) / 2 + 2rem);

  body > & {
    grid-column: 1/4;
    background: linear-gradient(to bottom, transparent, transparent 16rem, var(--c-teal-400) 16rem);
  }

  &.no-artwork {
    background: var(--c-teal-400);

    .footer__artwork {
      display: none;
    }
  }
}

.footer__artwork {
  /*clip-path: polygon(0 0, 100% 0, 100% 16rem, 0 16rem);*/
  display: block;
  grid-column: 1/4;
  justify-self: center;

  max-width: 100vw;
  margin: auto;
}

.footer__main,
.footer__impressum {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 40rem) {
    gap: 1rem;
  }
}

.footer__main {
  padding: 4rem var(--letterbox-size) 2rem;
  background-color: var(--c-teal-400);

  /*padding-top: 4rem;
  padding-bottom: 6rem;*/

  &__about {
    flex: 24rem 0 1;
  }
  &__about__text {
    margin: 1em 0;
  }

  /* Wrap on small screen sizes */
  @media (max-width: 40rem) {
    flex-wrap: wrap;
    justify-content: center;

    &__about {
      flex-basis: auto;
    }
  }


  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: start;

    @media (max-width: 40rem) {
    }


    a,
    a:visited {
      font-family: var(--title-font);
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.25rem;

      padding: .4rem .9rem;

      letter-spacing: 0.05em;
      text-transform: uppercase;
      text-decoration: none;

      color: var(--c-gray-900);
      white-space: nowrap;
    }

    /* Align footer links vertically on smaller screens */
    @media (max-width: 60rem) {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }
}



.footer__impressum {
  padding: 1rem var(--letterbox-size) 1rem;
  background-color: var(--c-teal-400);

  @media (max-width: 40rem) {
    justify-content: space-around;
    padding: 1rem;
  }

  /* social links */
  &__social {

    & a {
      padding: .85em;
      border-radius: .5em;
      color: var(--c-gray-900);
      /*TODO: make anchor with large tap surface*/

      &:hover,
      &:focus {
        background-color: var(--c-teal-500);
      }

      svg {
        width: auto;
        height: 1rem;
      }
    }

    @media (max-width: 40rem) {
      footer .impressum {
        padding: 2rem 1rem 1rem;
        gap: 1rem;
      }
    }
  }
}


/* TODO: footer quick fix, needs cleanup */
body > footer > * {
  width: 64rem;
  max-width: 100vw;
  margin: auto;
}


body > footer {
}





/* style.css | https://rustfest.global/css/style.css */

.footer__main {
  /* padding: 4rem var(--letterbox-size) 2rem; */
  padding: 4rem 0 2rem;
}

.footer__impressum {
  /* padding: 1rem var(--letterbox-size) 1rem; */
  padding: 1rem 0 1rem;
}

.footer__artwork {
  height: 17rem;
  margin-bottom: -1rem;
}

@media (max-width: 40rem) {
  .footer__impressum {
    /* padding: 1rem; */
    padding: 1vw;
  }
}

@media (max-width: 40rem) {
  .footer__impressum, .footer__main {
    padding: 2rem 1vw;
  }
}

.footer__main {
  /* padding: 4rem 0 2rem; */
}

/* Element | https://rustfest.global/ */

body > footer:nth-child(3) {
  place-items: center;
}





/* style.css | https://rustfest.global/css/style.css */

@media (max-width: 64rem) {
  .footer__impressum, .footer__main {
    justify-content: space-evenly;
  }
}
@media (max-width: 40rem) {
  .footer__main__about {
    margin: 0 1em;
    text-align: center;
    max-width: 32rem;
  }
}
