.team {
  width: 100%;
  display: grid;

  grid: auto / repeat(auto-fill, minmax(15rem, 1fr));
  gap: 3rem 1rem;

  list-style-type: none;
  margin: 0;
  padding: 0;

  /* Reduce gap on mobile size */
  @media (max-width: 32rem) {
    gap: 1rem;
  }

  /* Title of an event (team member listing) block */
  &__event {
    margin: 4rem 0 2rem;
    color: var(--c-teal-400);

    /* Skip the large top margin on first event */
    #team + & {
      margin-top: 0;
    }
  }


  &__member {
    color: white;

    display: grid;
    grid-template-areas:
      "photo name"
      "photo identities";

    gap: .25rem .675rem;

    justify-content: start;
    align-items: center;

    /* Team member photo */
    &__photo { /* also has .avatar-with-fallback */
      --avatar-size: 3.25rem;
      grid-area: photo;
    }

    &__name {
      grid-area: name;

      font-size: 1.125rem;
      line-height: 1;

      color: white;
      font-weight: bold;
    }

    &__identities {
      grid-area: identities;

      a {
        display: inline-block;
        border: .3rem solid transparent;
        width: 1.75rem;
        height: auto;
        margin-right: .25em;
      }
    }
  }
}
