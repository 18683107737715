.infobar_container {
  padding: 1.5rem 0;
  background: var(--c-gray-800);
}

.infobar {
  display: grid;

  grid-template-columns: 3rem 1fr auto;
  align-items: center;

  @media (max-width: 40rem) {
    grid-template-columns: 1fr;
    place-items: center;
  }


  &__label {
    margin-right: .5em;
    @media (max-width: 40rem) {
      display: none;
    }
  }
  
  time {
    border-radius: 0;
    background: transparent;
    font-weight: bold;
  }
  .action__buttons {
    @media (max-width: 40rem) {
      margin-top: 1rem;
    }
  }

  .session__scheduling {
    grid-area: initial;
    white-space: normal;
    justify-self: unset;

    @media (max-width: 40rem) {
      text-align: center;
    }
  }

  .timezone__box__change {
    color: var(--c-teal-400);

    &::before {
      filter: none;
    }

    &:active,
    &:focus,
    &:hover
    {
      filter: var(--to-white);
    }
  }
}



main.session_page {
  .session__tags {
    margin-top: 3rem;
    margin-bottom: -2rem;
  }
  .session__people {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: stretch;

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
    }
  }
  .session__person {
    display: grid;
    grid-template-areas:
      "picture name"
      "picture identities"
      "bio     bio";
    grid-template-columns: 5rem auto;
    grid-template-rows: 2.5rem 2.5rem auto;
    background: var(--c-gray-800);
    padding: 1.5rem;
    gap: .5rem 1rem;
  }
  .person {
    &__avatar {
      grid-area: picture;
      --avatar-size: 5rem;
      align-self: start;
      background-color: var(--c-teal-400);
      border-color: var(--c-teal-400);
    }
    &__name {
      grid-area: name;
      font-family: var(--title-font);
      font-size: 1.2rem;
      align-self: end;
    }
    &__identities {
      grid-area: identities;

      a {
        padding: .25rem;
        margin-right: .25em;
      }

      svg {
        preserveAspectRatio: "X20Y20 meet";
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    &__bio {
      grid-area: bio;
    }
  }
}
  
main.session_page {
  grid-column: 1/4;
  padding-bottom: 3rem;
}
@media (max-width: 64rem) {
  main.session_page .infobar {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
main.session_page > *,
main.session_page .infobar
{
  width: 64rem;
  max-width: calc(100vw - 2rem);
  margin-left: auto;
  margin-right: auto;
}
main.session_page > .infobar_container {
  width: 100%;
  max-width: 100vw;
  background: var(--c-gray-800);
  margin: 1rem auto 2rem;
}

main.session_page > .infobar_container > * {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
}


main.session_page > .session__title__translated {
  color: var(--c-magenta-400);
  font-size: 1.2rem;
  font-weight: 400;
}



.session__presented_by {
  margin-top: 3rem;
}