.sponsors {
  display: grid;

  width: 100%;
  max-width: none;

  grid-template-columns: repeat(auto-fit, 14rem);
  grid-auto-rows: 9rem;
  gap: 1rem;
  justify-content: center;

  list-style: none;
  padding: 0;

  @media (max-width: 40rem) {
    grid-template-columns: 20rem;
    grid-auto-rows: 11rem;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;
    border-radius: .75rem;

    a {
      display: contents;
    }

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
      border: 2rem solid transparent;
    }
  }
}
