:root {
  --c-gray-100: rgb(246,250,252);
  --c-gray-200: rgb(233,243,249);
  --c-gray-300: rgb(220,234,243);
  --c-gray-400: rgb(197,216,226);
  --c-gray-500: rgb(151,179,189);
  --c-gray-600: rgb(107,132,145);
  --c-gray-700: rgb(66,90,102);
  --c-gray-800: rgb(33,61,71);
  --c-gray-900: rgb(11,39,49);

  --c-magenta-100: rgb(255,245,248);
  --c-magenta-200: rgb(254,214,233);
  --c-magenta-300: rgb(253,178,225);
  --c-magenta-400: rgb(251,124,222);
  --c-magenta-500: rgb(251,80,233);
  --c-magenta-600: rgb(238,38,205);
  --c-magenta-700: rgb(208,30,171);
  --c-magenta-800: rgb(162,28,129);
  --c-magenta-900: rgb(117,32,95);

  --c-purple-100: rgb(250,245,255);
  --c-purple-200: rgb(232,216,254);
  --c-purple-300: rgb(213,188,252);
  --c-purple-400: rgb(184,146,252);
  --c-purple-500: rgb(165,116,252);
  --c-purple-600: rgb(138,79,246);
  --c-purple-700: rgb(115,61,218);
  --c-purple-800: rgb(91,54,173);
  --c-purple-900: rgb(70,48,130);

  --c-teal-100: rgb(235,255,247);
  --c-teal-200: rgb(188,246,223);
  --c-teal-300: rgb(141,230,199);
  --c-teal-400: rgb(91,210,171);
  --c-teal-500: rgb(64,182,146);
  --c-teal-600: rgb(52,156,127);
  --c-teal-700: rgb(46,127,106);
  --c-teal-800: rgb(40,98,85);
  --c-teal-900: rgb(34,81,71);

  --c-yellow-100: rgb(255,255,241);
  --c-yellow-200: rgb(254,251,197);
  --c-yellow-300: rgb(252,237,145);
  --c-yellow-400: rgb(251,221,93);
  --c-yellow-500: rgb(251,202,28);
  --c-yellow-600: rgb(214,163,18);
  --c-yellow-700: rgb(178,126,19);
  --c-yellow-800: rgb(146,93,16);
  --c-yellow-900: rgb(114,67,14);

  /* Color-changing filter presets */
  --to-white: grayscale(100%) brightness(200%);
}
