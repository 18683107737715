#recordings>ul {
  list-style-type: none;
  padding-left: 0;
}

#recordings>ul>li {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.85 3.88a.88.88 0 00-.5.16l-2.97 1.89V4.3c0-.7-.66-1.31-1.43-1.31H1.4C.63 3 0 3.6 0 4.31v7.9c0 .72.63 1.29 1.4 1.29h8.55c.77 0 1.43-.57 1.43-1.29v-1.6l2.98 1.88c.16.1.32.13.49.13.46 0 .9-.35.9-.84V4.75a.89.89 0 00-.9-.88zm-4.79 8.28c-.02.03-.05.03-.1.03H1.38c-.05 0-.08 0-.08-.03v-7.8c0-.02.03-.05.08-.05h8.56c.06 0 .09.03.11.06v7.79zm4.38-1.18l-3.06-1.91V7.46l3.06-1.92v5.44z' fill='%235BD2AB'/%3E%3C/svg%3E%0A");
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: .25rem 0;
  padding-left: 2.5rem;
  margin-bottom: 1em;
}
