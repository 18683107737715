.intouch {
  border-top: 1px solid var(--c-gray-500);
  margin-top: 4.5rem;
  padding-top: 2.5rem;

  display: grid;
  grid-template-areas: "form links";
  grid-template-columns: 3fr 1fr;

  &__subscribe {
    grid-area: form;

    &__input {
      margin: .75em 0;

      @media (max-width: 40rem) {
      	display: flex;
      	flex-direction: column;

        > button {
          border-bottom-left-radius: .25rem;
        }
      }
    }
  }
}

.intouch__channels {
  grid-area: links;
  align-self: center;

  color: var(--c-teal-400);

  list-style-type: none;
  line-height: 2rem;

  margin: 0;
  padding: 0 .5rem;
}

.intouch__channel {
  white-space: nowrap;
  width: min-content;
  margin: .4em 0 .6em;

  svg {
    width: 1rem;
    height: auto;
  }

  @media (max-width: 32rem) {
    margin: .4em auto .6em;
  }
}


@media (max-width: 40rem) {
  .intouch {
    grid-template-areas:
      "form "
      "links";

    grid-template-columns: 1fr;
    gap: 1rem;
  }

}
