[v-scope] {
  visibility: hidden;
}

.displayCurrency,
.currencies a {
  font-family: var(--title-font);
  color: var(--c-magenta-400);
  text-decoration: none;
  font-size: 1.4rem;
}

.currencies {
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  gap: .25rem;
  flex-wrap: wrap;

  a {
    padding: .1em;
    border: .2em solid transparent;
    border-radius: .2em;

    &.current {
      color: var(--c-teal-400);
      border-bottom-color: currentcolor;
    }

    &:hover, &:active {
      color: var(--c-teal-400);
    }
  }

  [href="#suggest"] {
    font-size: .96em;
  }
}

.tickets {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);

  margin: 3rem 0;

  @media (max-width: 40rem) {
    grid-template-columns: auto;
  }

  button,
  input[type=submit] {
    border-radius: .25rem;
    white-space: normal;
    align-self: stretch;
    margin: 0;

    &:not([disabled]):hover {
      background-color: var(--c-magenta-300);
    }
  }

  .box {
    grid-row-end: span 3;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: subgrid;
    align-items: start;

    border:.3rem solid transparent;

    &:target {
      border: .3rem solid var(--c-magenta-400);
    }

    .box__noodle {
      font-size: 1.2rem;
      background-color: var(--c-magenta-400);
      color: var(--c-gray-100);
      padding: .25rem .5rem;
      margin-top: -.3rem;
      margin-right: -.5rem;
    }
    .ticket__info {
      font-size: .875rem;
      line-height: 1.4;
    }
  }
}

.checkout {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: .5em;
  padding: 2rem;

  @media (max-width: 40rem) {
    padding: 0;
  }

  input:user-invalid {
    background-color: var(--c-magenta-900);
  }
}


#ticketing-notes + ul {
  font-style: italic;
  padding-left: 1.5em;
}
#ticketing-notes + ul > li {
  margin-bottom: .5em;
}

#pwycform,
#reminder,
#suggest {
  background: var(--c-gray-800);
  border-radius: .5rem;
  padding: 1rem 2rem;
  max-width: 40em;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'%3E%3Cpath d='M1 1 11 11M1 11 11 1' stroke='white' stroke-width='1' fill='none'/%3E%3C/svg%3E");
    background-color:var(--c-gray-700);
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    padding: 1rem;
    width: 4rem;
    height: 3rem;
    min-width: 0;
  }
  h4 {
    margin: auto;
    text-align: center;
  }

  > *, > .component > * {
    min-width: 75%;
  }

  &:target {
    border: .3rem solid var(--c-magenta-400);
  }

  @media (max-width: 40rem) {
    align-items: stretch;
    text-align: center;
    input {
      text-align: center;
    }
  }
}

.component {
  display: contents;
}

#notify {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: var(--title-font);
  color: var(--c-purple-400);
  text-align: center;
  font-size: 2rem;
  line-height: 1.5;
  padding: 6rem 1rem 12rem;
  background: linear-gradient(to bottom, var(--c-teal-900) 60%, rgba(0,0,0,0));
  transition: all 4s ease 2s;
  opacity: 1;
  pointer-events: none;
}
#notify.fade {
  opacity: 0
}