main.schedule {
  h1 {
    margin-top: 0;
  }

  @media (max-width: 30rem) {
    margin: 0 -1.25rem;

    + footer {
      margin: 0 -2rem;
    }
  }
}