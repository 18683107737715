.session {
  padding: 1.375rem;
  border-radius: .25rem;

  background: var(--c-gray-800);
  color: var(--c-gray-100);

  display: grid;

  grid-template-columns: 3fr 1fr;
  grid-template-areas:
    "people   scheduling"
    "details  actions";

  margin-bottom: .5rem;
  .event > & {
    margin-bottom: .25rem;
  }


  @media (max-width: 40rem) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "people   scheduling"
      "details  details"
      "actions  actions";
  }

  &__details {
    grid-area: details;
  }

  &__scheduling {
    grid-area: scheduling;
    padding: .5rem 1rem;
    font-size: .875rem;
    background-color: var(--c-gray-700);
    border-radius: 2rem;
    justify-self: center;
    white-space: nowrap;

    /* Align to screen-right in mobile viewports */
    @media (max-width: 40rem) {
      align-self: start;
      justify-self: end;
    }
  }

  /* Separate session color variant for entertainment sessions */
  &.session-type-sketchnoting,
  &.session-type-entertainment {
    background: var(--c-purple-900);

    .session__scheduling {
      background: var(--c-purple-700);
    }
  }

  &__title {
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin: .5rem 0 1rem;

    > a {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus
      {
        color: var(--c-magenta-300);
      }
    }
  }
  &__title__translated {
    color: var(--c-magenta-400);
    font-size: 1rem;
    font-weight: normal;

    /* Push it close to the to the actual title */
    margin: -1rem 0 1rem;
  }

  &__pitch {
    max-width: 36rem;
    font-size: .875rem;
    margin: 0 0 1rem;

    blockquote, ol, p, ul
    {
      font-size: inherit;
    }
  }

  &__pitch__translated {
    max-width: 36rem;
    font-size: .875rem;
    margin: 0 0 1rem;

    color: var(--c-gray-500);
    border-top: 1px solid var(--c-gray-700);
    padding-top: .675rem;

    blockquote, ol, p, ul
    {
      font-size: inherit;
    }
  }


  &__people {
    grid-area: people;
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    gap: 1rem;

    @media (max-width: 40rem) {
      flex-wrap: wrap;
      gap: .2rem;
    }
  }
  &__person {
    display: flex;
    gap: .5em;
    align-items: center;
    font-size: .875rem;

    .avatar-with-fallback {
      --avatar-size: 1.75rem;
      background-color: var(--c-purple-500);
      border-color: var(--c-purple-500);
    }
  }
}