/* Box container */
.boxes {
  display: grid;
  gap: 1.5rem;

  /* Allow wrapping on mobile */
  @media (min-width: 48rem) {
    grid-template-columns: repeat(3,1fr);
  }
}

.box {display: flex;
  background-color: var(--c-gray-800);

  padding: 1.65rem;
  border-radius: .5rem;

  flex: 1;

  /* Distribute box contents nicely */
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  /* Reset for boxes with links */
  color: white;
  text-decoration: none;


  /* Smooth transitions between activation states */
  transition: .4s all;

  /* If the box is a link we highlight it on hover/focus */
  &[href]:hover,
  &[href]:active
  {
    background-color: var(--c-gray-900);

    /* Card glow teal */
    box-shadow: 0px 0px 10px #00CFAB;
    border-radius: .5rem;

    cursor: pointer;
  }
  &[href]:focus {
    outline: .2rem solid var(--c-magenta-400);
  }

  /* Link boxes with no link */
  &.linkbox {
    flex: 11rem 0 1;

    &:not([href]) {
      color: var(--c-gray-400);
      opacity: .33;
      position: relative;

      .box__noodle {
        background-color: var(--c-gray-700);
        color: var(--c-gray-500);

        strong {
          font-size: 1.375rem;
        }
      }

      &:after {
        content: 'Unavailable';
        position: absolute;

        font-family: var(--title-font);
        text-transform: uppercase;
        font-size: 1.75rem;
        font-weight: bold;

        color: var(--c-magenta-300);
        text-shadow:
           0    0   1px var(--c-gray-800),
           1px  1px 2px var(--c-gray-800),
          -1px  1px 2px var(--c-gray-800),
          -1px -1px 2px var(--c-gray-800),
           1px -1px 2px var(--c-gray-800);

        transform:rotate(-45deg);
        top: 45%;
        left:50%;
        margin-left: -3em;
      }
    }
  }

  /* Full-width on mobile */
  @media (max-width: 40rem) {
    flex-basis: max-content;
  }


  /* CHILD COMPONENTS */

  /* Large highlighted box title */
  &__title {
    font-family: var(--title-font);
    font-size: 1.25rem;
    color: var(--c-teal-400);

    /*display: flex;*/
    min-height: 2em;
  }

  /* Small flowing text */
  &__content {
    font-size: .875rem;
    margin: 1em 0;
  }

  /* Colorful "noodle" */
  &__noodle {
    background-color: var(--c-magenta-300);
    color: var(--c-magenta-900);

    font-size: .75rem;

    padding: .2rem .45rem;
    border-radius: .5rem;

    /* to make sure it is not stretched */
    align-self: start;
  }
}
